<template>
  <v-container
    fluid
    class="ma-0 pa-0 ma-0 pa-0 fill-height"
    style="margin: 0px; padding: 0px; width: 100%"
  >
    <v-row
      class="ma-0 pa-0"
    >
      <v-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        class="black--text pt-0 text-center ma-0 pa-0 col-12 xs"
      >
        <div
          id="topdiva"
          style="height:92vh"
        >
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 60px"
          >
            <v-row
              dense
              align="center"
              justify="center"
            >
              <v-col
                class="xs"
                cols="0"
                xl="2"
                lg="2"
                md="1"
                sm="0"
              />
              <v-col
                cols="12"
                xl="8"
                lg="8"
                md="10"
                sm="12"
                class="d-flex align-center justify-center xs"
              >
                <img
                  class="mr-0"
                  :src="require('@/assets/logo.png')"
                  style="display: inline-block; width: 90%; max-width: 560px; height: auto;"
                >
              </v-col>
              <v-col
                class="xs"
                cols="0"
                xl="2"
                lg="2"
                md="1"
                sm="0"
              />
            </v-row>
          </v-container>
        </div>
        <div
          id="topdiv"
          style="height:92vh"
        >
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 60px"
          >
            <v-row
              class="height"
              no-gutters
              align="center"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="0"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="12"
                sm="12"
                cols="12"
              >
                <v-row
                  class="height"
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    cols="12"
                  >
                    <div
                      id="img1"
                      style="vertical-align: middle; padding-bottom: 5px; padding-top: 5px"
                    >
                      <img
                        class="mr-0"
                        :src="computedsupplierimage"
                        style="cursor: pointer; margin-left: auto; vertical-align: middle; margin-right: auto; display: inline-block; max-width: 350px; height: auto; max-height: 28vh;"
                        @click="scrollToservices1"
                      >
                    </div>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    cols="12"
                  >
                    <div
                      id="img2"
                      style="vertical-align: middle; padding-bottom: 5px; padding-top: 5px"
                    >
                      <img
                        class="mr-0"
                        :src="computedprojectimage"
                        style="cursor: pointer; margin-left: auto; vertical-align: middle; margin-right: auto; display: inline-block; max-width: 350px; height: auto; max-height: 28vh;"
                        @click="scrollToservices2"
                      >
                    </div>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    cols="12"
                  >
                    <div
                      id="img3"
                      style="vertical-align: middle; padding-bottom: 5px; padding-top: 5px"
                    >
                      <img
                        class="mr-0"
                        :src="computedinterimimage"
                        style="cursor: pointer; margin-left: auto; vertical-align: middle; margin-right: auto; display: inline-block; max-width: 350px; height: auto; max-height: 28vh;"
                        @click="scrollToservices3"
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="0"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
        <v-divider />
        <div id="services1">
          <v-card-text
            style="font-size: 50px"
            class="headline text-center font-weight-bold"
          >
            {{ this.$t('message.services_title') }}
          </v-card-text>
          <v-row
            dense
            justify="center"
            align="center"
          >
            <v-col
              class="xs"
              cols="0"
              xl="2"
              lg="2"
              md="1"
              sm="0"
            />
            <v-col
              class="xs"
              cols="12"
              xl="8"
              lg="8"
              md="10"
              sm="12"
            >
              <v-card-text
                style="font-size: 35px"
                class="headline text-center"
              >
                {{ this.$t('message.services1_subtitle') }}
              </v-card-text>
              <v-card-text
                style="font-size: 18px"
                class="text-justify"
                v-html="$t('message.services1_maintext1')"
              />
              <v-row
                dense
                justify="center"
                align="center"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <img
                      :src="computedimage"
                      height="200"
                      width="95%"
                      style="display: inline-block; height: auto"
                    >
                  </v-lazy>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services1_maintext2')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="xs"
              cols="0"
              xl="2"
              lg="2"
              md="1"
              sm="0"
            />
          </v-row>
          <v-row
            dense
            justify="center"
            align="center"
          >
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
            <v-col
              xl="8"
              lg="8"
              md="10"
              sm="12"
              cols="12"
            >
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: 0
                }"
                min-height="200"
                transition="fade-transition"
              >
                <img
                  class="mr-0"
                  :src="require('@/assets/services_suivi2.png')"
                  width="95%"
                  style="display: inline-block; height: auto"
                >
              </v-lazy>
            </v-col>
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
            <br>
          </v-row>
          <v-divider />
        </div>
        <div id="services2">
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              dense
              align="center"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="align-center justify-center"
              >
                <v-card-text
                  style="font-size: 35px"
                  class="headline text-center"
                >
                  {{ this.$t('message.services2_subtitle') }}
                </v-card-text>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services2_maintext1')"
                  />
                </v-lazy>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services2_maintext2')"
                  />
                </v-lazy>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services2_maintext3')"
                  />
                </v-lazy>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <br>
            </v-row>
          </v-container>
          <v-divider />
        </div>
        <div id="services3">
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 124px"
          >
            <v-row
              dense
              align="center"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="align-center justify-center"
              >
                <v-card-text
                  style="font-size: 35px"
                  class="headline text-center"
                >
                  {{ this.$t('message.services3_subtitle') }}
                </v-card-text>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services3_maintext1')"
                  />
                </v-lazy>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services3_maintext2')"
                  />
                </v-lazy>
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="text-justify"
                    v-html="$t('message.services3_maintext3')"
                  />
                </v-lazy>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
        <div
          id="market"
          style="height:92vh"
        >
          <v-container
            class="ma-0 pt-0 pl-0 pr-0"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              no-gutters
              align="start"
              class="align-start"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="d-flex align-start"
              >
                <v-card-text
                  style="font-size: 50px"
                  class="headline text-center font-weight-bold"
                >
                  {{ this.$t('message.market_title') }}
                </v-card-text>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 124px"
          >
            <v-row
              class="height"
              dense
              align="center"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="d-flex align-center fill-height"
              >
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <img
                    class="mr-0"
                    :src="require('@/assets/marches1_tr.png')"
                    width="80%"
                    max-height="80%"
                    style="vertical-align: middle"
                  >
                </v-lazy>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
        <v-divider />
        <div
          id="news"
        >
          <v-container
            class="ma-0 pt-0 pl-0 pr-0"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              no-gutters
              align="start"
              class="d-flex align-start"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="d-flex align-start"
              >
                <v-card-text
                  style="font-size: 50px"
                  class="headline text-center font-weight-bold"
                >
                  {{ this.$t('message.news_title') }}
                </v-card-text>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 124px"
          >
            <v-row
              class="width height"
              dense
              align="center"
              justify="center"
            >
              <v-col
                xl="4"
                lg="4"
                md="2"
                sm="0"
                cols="0"
              />
              <v-col
                xl="4"
                lg="4"
                md="8"
                sm="12"
                cols="12"
                class="d-flex align-center fill-height width"
              >
                <v-card
                  width="80%"
                  class="d-inline-block mx-auto"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    transition="fade-transition"
                  >
                    <v-card-text
                      :elevation="30"
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.news_triumf')"
                    />
                  </v-lazy>
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <img
                      class="mr-0"
                      :src="require('@/assets/triumf.png')"
                      style="display: inline-block; width: 100%; max-width: 300x; height: auto;"
                    >
                  </v-lazy>
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    transition="fade-transition"
                  >
                    <v-card-text
                      :elevation="30"
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.news_triumf2')"
                    />
                  </v-lazy>
                </v-card>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="2"
                sm="0"
                cols="0"
              />
            </v-row>
            <v-row
              class="width height"
              dense
              align="center"
              justify="center"
            >
              <v-col
                xl="4"
                lg="4"
                md="2"
                sm="0"
                cols="0"
              />
              <v-col
                xl="4"
                lg="4"
                md="8"
                sm="12"
                cols="12"
                class="d-flex align-center fill-height width"
              >
                <v-card
                  width="80%"
                  class="d-inline-block mx-auto"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    transition="fade-transition"
                  >
                    <v-card-text
                      :elevation="30"
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.news1_maintext1')"
                    />
                  </v-lazy>
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <img
                      class="mr-0"
                      :src="require('@/assets/actu1_tr.png')"
                      style="display: inline-block; width: 100%; max-width: 240px; height: auto;"
                    >
                  </v-lazy>
                </v-card>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="2"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
        <div id="about">
          <v-container
            class="ma-0 pt-0 pl-0 pr-0"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              dense
              justify="center"
              align="center"
            >
              <v-col
                class="xs"
                cols="0"
                xl="2"
                lg="2"
                md="1"
                sm="0"
              />
              <v-col
                class="xs"
                cols="12"
                xl="8"
                lg="8"
                md="10"
                sm="12"
              >
                <v-card-text
                  style="font-size: 50px"
                  class="headline text-center font-weight-bold"
                >
                  {{ this.$t('message.about_title') }}
                </v-card-text>
                <v-card-text
                  style="font-size: 35px"
                  class="headline text-center"
                >
                  {{ this.$t('message.about1_subtitle') }}
                </v-card-text>
                <v-row
                  dense
                  justify="center"
                  align="center"
                >
                  <v-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    class="align-center justify-center xs"
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="200"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0"
                        :src="require('@/assets/laurent.png')"
                        height="350"
                      >
                    </v-lazy>
                  </v-col>
                  <v-col
                    class="xs"
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="200"
                      transition="fade-transition"
                    >
                      <v-card-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                      >
                        <strong>Laurent NOELL </strong>
                        <a
                          :href="`${linkedinl_link}`"
                        >
                          <v-icon
                            style="background: #af9b62; fill: white"
                            size="21px"
                          >
                            {{ icon_linkedin }}
                          </v-icon>
                        </a>
                        <v-text
                          style="font-size: 18px"
                          class="black--text text-justify"
                          v-html="$t('message.about1_maintext1')"
                        />
                      </v-card-text>
                    </v-lazy>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="xs"
                cols="0"
                xl="2"
                lg="2"
                md="1"
                sm="0"
              />
            </v-row>
          </v-container>
          <v-container
            class="ma-0 pt-0 pl-0 pr-0"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              no-gutters
              align="start"
              class="d-flex align-start"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="d-flex align-start"
              >
                <v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0
                  }"
                  min-height="200"
                  transition="fade-transition"
                >
                  <v-card-text
                    style="font-size: 18px"
                    class="black--text text-justify"
                    v-html="$t('message.about1_maintext2')"
                  />
                </v-lazy>
                <br>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
        <v-container
          class="ma-0 pt-0 pl-0 pr-0"
          fluid
          style="padding-bottom: 0px"
        >
          <v-row
            no-gutters
            align="start"
            class="d-flex align-start"
            justify="center"
          >
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
            <v-col
              xl="8"
              lg="8"
              md="10"
              sm="12"
              cols="12"
              class="d-flex align-start"
            >
              <v-card-text
                style="font-size: 35px"
                class="black--text headline text-center"
              >
                {{ this.$t('message.about3_subtitle') }}
              </v-card-text>
              <br>
            </v-col>
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
          </v-row>
        </v-container>
        <v-container
          class="ma-0 pt-0 pl-0 pr-0"
          fluid
          style="padding-bottom: 0px"
        >
          <v-row
            no-gutters
            align="start"
            class="d-flex align-start"
            justify="center"
          >
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
            <v-col
              xl="8"
              lg="8"
              md="10"
              sm="12"
              cols="12"
              class="d-flex align-start"
            >
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: 0
                }"
                min-height="200"
                transition="fade-transition"
              >
                <v-card-text
                  style="font-size: 18px"
                  class="black--text text-justify"
                  v-html="$t('message.about3_maintext1')"
                />
              </v-lazy>
              <br>
            </v-col>
            <v-col
              xl="2"
              lg="2"
              md="1"
              sm="0"
              cols="0"
            />
          </v-row>
        </v-container>
        <v-container
          class="ma-0 pt-0 pl-0 pr-0"
          fluid
          style="padding-bottom: 0px"
        >
          <v-row
            dense
            justify="center"
            align="center"
          >
            <v-col
              class="xs"
              cols="0"
              xl="2"
              lg="2"
              md="1"
              sm="0"
            />
            <v-col
              class="xs"
              cols="12"
              xl="8"
              lg="8"
              md="10"
              sm="12"
            >
              <v-card-text
                style="font-size: 35px"
                class="headline text-center"
              >
                {{ this.$t('message.about4_subtitle') }}
              </v-card-text>
              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec6.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec6_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec6_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec6_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext6')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec1.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec1_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec1_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec1_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext1')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>
              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec2.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec2_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec2_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec2_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext2')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec3.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec3_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec3_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec3_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext3')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec4.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec4_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec4_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec4_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext4')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                align="start"
              >
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  class="align-center justify-center xs"
                >
                  <v-container
                    class="pa-6 align-center justify-center"
                    fluid
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="50"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0 align-center justify-center"
                        :src="require('@/assets/rec5.png')"
                        height="80"
                      >
                    </v-lazy>
                    <v-text
                      style="font-size: 18px"
                      class="font-weight-bold black--text text-justify"
                      v-html="$t('message.about4_rec5_1')"
                    /><br>
                    <v-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec5_2')"
                    /><br>
                    <v-text
                      style="font-size: 14px"
                      class="black--text text-justify"
                      v-html="$t('message.about4_rec5_3')"
                    /><br>
                  </v-container>
                </v-col>
                <v-col
                  class="xs"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0
                    }"
                    min-height="200"
                    transition="fade-transition"
                  >
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-justify"
                    >
                      <v-text
                        style="font-size: 18px"
                        class="black--text text-justify"
                        v-html="$t('message.about4_maintext5')"
                      />
                    </v-card-text>
                  </v-lazy>
                </v-col>
              </v-row>

            </v-col>
            <v-col
              class="xs"
              cols="0"
              xl="2"
              lg="2"
              md="1"
              sm="0"
            />
          </v-row>
        </v-container>
        <br>
        <div
          id="contact"
        >
          <v-container
            class="ma-0 pt-0 pl-0 pr-0"
            fluid
            style="padding-bottom: 0px"
          >
            <v-row
              no-gutters
              align="start"
              class="align-start"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
                class="d-flex align-start"
              >
                <v-card-text
                  style="font-size: 50px"
                  class="headline text-center font-weight-bold"
                >
                  {{ this.$t('message.contact_title') }}
                </v-card-text>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
          <v-container
            class="ma-0 pt-0 pl-0 pr-0 fill-height"
            fluid
            style="padding-bottom: 124px"
          >
            <v-row
              class="height"
              no-gutters
              align="center"
              justify="center"
            >
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
              <v-col
                xl="8"
                lg="8"
                md="10"
                sm="12"
                cols="12"
              >
                <v-row
                  class="height"
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col
                    xl="2"
                    lg="2"
                    md="3"
                    sm="4"
                    cols="4"
                  >
                    <v-row
                      class="height"
                      no-gutters
                      align="center"
                      justify="center"
                    >
                      <v-col cols="12">
                        <v-lazy
                          v-model="isActive"
                          :options="{
                            threshold: 0
                          }"
                          transition="fade-transition"
                        >
                          <img
                            class="mr-0"
                            :src="require('@/assets/logo.png')"
                            height="80"
                          >
                        </v-lazy>
                      </v-col>
                      <v-col cols="12">
                        <v-card-text
                          justify="start"
                          style="font-size: 18px"
                          class="black--text text-center"
                          v-html="address"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="4"
                  >
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      min-height="200"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0"
                        :src="require('@/assets/carte.png')"
                        style="display: inline-block; width: 90%; max-width: 200px; height: auto;"
                      >
                    </v-lazy>
                  </v-col>
                  <v-col
                    xl="2"
                    lg="2"
                    md="3"
                    sm="4"
                    cols="4"
                  >
                    <v-icon
                      color="black"
                      size="40px"
                    >
                      {{ icon_email }}
                    </v-icon>
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-center"
                    >
                      contact@noell.fr
                    </v-card-text>
                    <v-icon
                      color="black"
                      size="40px"
                    >
                      {{ icon_phone }}
                    </v-icon>
                    <v-card-text
                      style="font-size: 18px"
                      class="black--text text-center"
                    >
                      +33 (0) 678.663.310
                    </v-card-text>
                    <a
                      :href="`${linkedin_link}`"
                    >
                      <v-icon
                        color="blue darken-4"
                        size="40px"
                      >
                        {{ icon_linkedin }}
                      </v-icon>
                    </a>
                  </v-col>
                </v-row>
                <br>
                <br>
                <br>
                <br>
                <v-row
                  class="height"
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col
                    xl="3"
                    lg="3"
                    md="3"
                    sm="2"
                    cols="1"
                  />
                  <v-col>
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      transition="fade-transition"
                    >
                      <v-card-text
                        style="font-size: 18px"
                        class="black--text justify-center"
                        v-html="$t('message.membrede')"
                      />
                    </v-lazy>
                  </v-col>
                  <v-col>
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0"
                        :src="require('@/assets/cadres_tr.png')"
                        style="display: inline-block; width: 90%; max-width: 110px; height: auto;"
                      >
                    </v-lazy>
                  </v-col>
                  <v-col>
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0"
                        :src="require('@/assets/managers_tr.png')"
                        style="display: inline-block; width: 90%; max-width: 120px; height: auto;"
                      >
                    </v-lazy>
                  </v-col>
                  <v-col
                    xl="3"
                    lg="3"
                    md="3"
                    sm="2"
                    cols="1"
                  />
                </v-row>
                <v-row
                  class="height"
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <v-col
                    xl="3"
                    lg="3"
                    md="3"
                    sm="2"
                    cols="1"
                  />
                  <v-col>
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      transition="fade-transition"
                    >
                      <v-card-text
                        style="font-size: 18px"
                        class="black--text justify-center"
                        v-html="$t('message.couveo')"
                      />
                    </v-lazy>
                  </v-col>
                  <v-col>
                    <v-lazy
                      v-model="isActive"
                      :options="{
                        threshold: 0
                      }"
                      transition="fade-transition"
                    >
                      <img
                        class="mr-0"
                        :src="require('@/assets/logo_couveo.png')"
                        style="display: inline-block; width: 90%; max-width: 110px; height: auto;"
                      >
                    </v-lazy>
                  </v-col>
                  <v-col
                    xl="3"
                    lg="3"
                    md="3"
                    sm="2"
                    cols="1"
                  />
                </v-row>
              </v-col>
              <v-col
                xl="2"
                lg="2"
                md="1"
                sm="0"
                cols="0"
              />
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'PageJumbotron',

    props: {
      heading: String,
      icon: String,
      view: String,
    },
    data: () => ({
      items: [
        {
          action: 'services',
          title: 'Services',
          active: true,
          items: [
            { title: 'services1' },
            { title: 'services2' },
            { title: 'services3' },
          ],
        },
      ],
      items_services_sub: [
        { div: 'services1' },
        { div: 'services2' },
        { div: 'services3' },
      ],
      date: (new Date()).getFullYear(),
      address: 'BP 11<br/>56370 SARZEAU<br/>France',
      icon_email: '$mdiEmailSend',
      icon_linkedin: '$mdiLinkedin',
      icon_phone: '$mdiPhoneForward',
      linkedin_link: 'https://linkedin.com/company/noell-international/',
      linkedinl_link: 'https://www.linkedin.com/in/laurentnoell/',
    }),
    computed: {
      ...sync('frontend/*'),
      computedimage: function () {
        return require('../../assets/' + this.$t('message.img1'))
      },
      computedsupplierimage: function () {
        return require('../../assets/' + this.$t('supplierimage'))
      },
      computedprojectimage: function () {
        return require('../../assets/' + this.$t('projectimage'))
      },
      computedinterimimage: function () {
        return require('../../assets/' + this.$t('interimimage'))
      },
    },
    methods: {
      changeLocale: function (locale) {
        this.$i18n.locale = locale
      },
      m_items_services: function (index) {
        return this.$t('message.ourservices_sub')[index]
      },
      top () {
        window.scrollTo(0, 0, 'smooth')
      },

      scrollTotop: function () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('topdiv')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToservices: function (adiv) {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById(adiv)
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },

      scrollTomarket () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('market')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollTonews () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('news')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToabout () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('about')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToservices3 () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('services3')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToservices1 () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('services1')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollToservices2 () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('services2')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
      scrollTocontact () {
        const options = {
          container: 'body',
          easing: 'ease-in',
          offset: -60,
          force: true,
          cancelable: true,
          onStart: function (element) {
            // scrolling started
          },
          onDone: function (element) {
            // scrolling is done
          },
          onCancel: function () {
            // scrolling has been interrupted
          },
          x: false,
          y: true,
        }
        const element = document.getElementById('contact')
        this.$scrollTo(element, '500', options)
        this.drawer = false
      },
    },
  }
</script>
